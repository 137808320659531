import React from 'react';
import { Avatar, Typography, Button, Chip, Link, Box, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { UserProvider, useUserContext } from './context';
import './index.scss';

function UserContainer() {
  return (
    <UserProvider>
      <User />
    </UserProvider>
  );
}

function User() {
  const { userData } = useUserContext();

  return (
    <div className="user-profile">
      <Paper elevation={0} className="profile-header">
        <Avatar
          alt={userData.name}
          src={`https://github.com/${userData.username}.png`}
          className="profile-avatar"
          sx={{ width: 260, height: 260 }}
        />
        
        <div className="profile-info">
          <Typography variant="h4" component="h1">{userData.name}</Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>{userData.username}</Typography>
          
          <Button variant="contained" color="primary" startIcon={<PersonAddIcon />}>
            Follow
          </Button>

          <div className="profile-stats">
            <Link href="#followers" underline="none" className="stat-link">
              <PeopleIcon fontSize="small" />
              <span>{userData.followers}</span> followers
            </Link>
            <Link href="#following" underline="none" className="stat-link">
              <span>{userData.following}</span> following
            </Link>
          </div>

          <div className="profile-links">
            {userData.location && (
              <Typography variant="body2" className="profile-link">
                <LocationOnIcon fontSize="small" />
                {userData.location}
              </Typography>
            )}
            {userData.website && (
              <Link href={`https://${userData.website}`} className="profile-link" target="_blank">
                <LinkIcon fontSize="small" />
                {userData.website}
              </Link>
            )}
            {userData.twitter && (
              <Link href={`https://twitter.com/${userData.twitter.substring(1)}`} className="profile-link" target="_blank">
                <TwitterIcon fontSize="small" />
                {userData.twitter}
              </Link>
            )}
            {userData.linkedin && (
              <Link href={`https://linkedin.com/${userData.linkedin}`} className="profile-link" target="_blank">
                <LinkedInIcon fontSize="small" />
                {userData.linkedin}
              </Link>
            )}
          </div>
        </div>
      </Paper>

      <Paper elevation={0} className="profile-content">
        <Box className="bio-section">
          {userData.bio.map((line, index) => (
            <Typography key={index} variant="body1" paragraph>
              {line}
            </Typography>
          ))}
        </Box>

        <Box className="skills-section">
          {userData.skills.map((skill) => (
            <Chip key={skill} label={skill} variant="outlined" className="skill-chip" />
          ))}
        </Box>
      </Paper>
    </div>
  );
}

export default UserContainer;
