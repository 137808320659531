import React, { createContext, useContext, useState } from 'react';

const mockUserData = {
  name: 'Jacq de Beer',
  username: 'jdebeer',
  location: 'New York, NY',
  website: 'ajdebeer.com',
  twitter: '@jacqdebeer',
  linkedin: 'in/ajdebeer',
  followers: 16,
  following: 36,
  bio: [
    '🚀 I work as a Founder, Software Engineer, Solutions Architect, Engineering Manager, and Product Manager',
    '👷‍♂️ Right now, I\'m workign on EntityBox—graphs, LLMs, big data, and a reimagined OS',
    '🗒 I write about my journey at ajdebeer.com',
    '🔧 I usually wield JavaScript/TypeScript, PostgreSQL, React, Next.js, and Node.js (plus Python for sneaky scripts). Past flings include PHP, C++, C, and Ruby/RoR, but they\'re mostly retired.'
  ],
  skills: ['JavaScript', 'TypeScript', 'React', 'Node.js']
};

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(mockUserData);

  const value = {
    userData,
    setUserData,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
} 